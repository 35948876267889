import { default as ajudaQPvn8ISHdcMeta } from "/vercel/path0/apps/client/pages/ajuda.vue?macro=true";
import { default as cadastrarqJFvapAppzMeta } from "/vercel/path0/apps/client/pages/cadastrar.vue?macro=true";
import { default as entrarGydni10AzUMeta } from "/vercel/path0/apps/client/pages/entrar.vue?macro=true";
import { default as esqueci_45minha_45senhatp2vZEtY7PMeta } from "/vercel/path0/apps/client/pages/esqueci-minha-senha.vue?macro=true";
import { default as index3sh8z89WVFMeta } from "/vercel/path0/apps/client/pages/index.vue?macro=true";
import { default as inicio0QimGmF475Meta } from "/vercel/path0/apps/client/pages/inicio.vue?macro=true";
import { default as blocoTE2O3qv1XNMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/bloco.vue?macro=true";
import { default as historicohkCcXeCsKYMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/index/historico.vue?macro=true";
import { default as index9N7gjpOL9aMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/index/index.vue?macro=true";
import { default as indexCmDjfhSsL5Meta } from "/vercel/path0/apps/client/pages/materia/[subject]/index.vue?macro=true";
import { default as assinaturayLaBqtJsdMMeta } from "/vercel/path0/apps/client/pages/minha-conta/assinatura.vue?macro=true";
import { default as index73yEVYZ2JDMeta } from "/vercel/path0/apps/client/pages/minha-conta/index.vue?macro=true";
import { default as senhaLp41UtBXYdMeta } from "/vercel/path0/apps/client/pages/minha-conta/senha.vue?macro=true";
import { default as minha_45contaC5nRlwebHmMeta } from "/vercel/path0/apps/client/pages/minha-conta.vue?macro=true";
import { default as _91id_93Floo5nXwh2Meta } from "/vercel/path0/apps/client/pages/questoes/[id].vue?macro=true";
import { default as sairvbAL15Uf6IMeta } from "/vercel/path0/apps/client/pages/sair.vue?macro=true";
import { default as sucessoMA1aLvmMgMMeta } from "/vercel/path0/apps/client/pages/sucesso.vue?macro=true";
export default [
  {
    name: ajudaQPvn8ISHdcMeta?.name ?? "ajuda",
    path: ajudaQPvn8ISHdcMeta?.path ?? "/ajuda",
    meta: ajudaQPvn8ISHdcMeta || {},
    alias: ajudaQPvn8ISHdcMeta?.alias || [],
    redirect: ajudaQPvn8ISHdcMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/ajuda.vue").then(m => m.default || m)
  },
  {
    name: cadastrarqJFvapAppzMeta?.name ?? "cadastrar",
    path: cadastrarqJFvapAppzMeta?.path ?? "/cadastrar",
    meta: cadastrarqJFvapAppzMeta || {},
    alias: cadastrarqJFvapAppzMeta?.alias || [],
    redirect: cadastrarqJFvapAppzMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: entrarGydni10AzUMeta?.name ?? "entrar",
    path: entrarGydni10AzUMeta?.path ?? "/entrar",
    meta: entrarGydni10AzUMeta || {},
    alias: entrarGydni10AzUMeta?.alias || [],
    redirect: entrarGydni10AzUMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/entrar.vue").then(m => m.default || m)
  },
  {
    name: esqueci_45minha_45senhatp2vZEtY7PMeta?.name ?? "esqueci-minha-senha",
    path: esqueci_45minha_45senhatp2vZEtY7PMeta?.path ?? "/esqueci-minha-senha",
    meta: esqueci_45minha_45senhatp2vZEtY7PMeta || {},
    alias: esqueci_45minha_45senhatp2vZEtY7PMeta?.alias || [],
    redirect: esqueci_45minha_45senhatp2vZEtY7PMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/esqueci-minha-senha.vue").then(m => m.default || m)
  },
  {
    name: index3sh8z89WVFMeta?.name ?? "index",
    path: index3sh8z89WVFMeta?.path ?? "/",
    meta: index3sh8z89WVFMeta || {},
    alias: index3sh8z89WVFMeta?.alias || [],
    redirect: index3sh8z89WVFMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inicio0QimGmF475Meta?.name ?? "inicio",
    path: inicio0QimGmF475Meta?.path ?? "/inicio",
    meta: inicio0QimGmF475Meta || {},
    alias: inicio0QimGmF475Meta?.alias || [],
    redirect: inicio0QimGmF475Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/inicio.vue").then(m => m.default || m)
  },
  {
    name: blocoTE2O3qv1XNMeta?.name ?? "materia-subject-bloco",
    path: blocoTE2O3qv1XNMeta?.path ?? "/materia/:subject()/bloco",
    meta: blocoTE2O3qv1XNMeta || {},
    alias: blocoTE2O3qv1XNMeta?.alias || [],
    redirect: blocoTE2O3qv1XNMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/bloco.vue").then(m => m.default || m)
  },
  {
    name: indexCmDjfhSsL5Meta?.name ?? undefined,
    path: indexCmDjfhSsL5Meta?.path ?? "/materia/:subject()",
    meta: indexCmDjfhSsL5Meta || {},
    alias: indexCmDjfhSsL5Meta?.alias || [],
    redirect: indexCmDjfhSsL5Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index.vue").then(m => m.default || m),
    children: [
  {
    name: historicohkCcXeCsKYMeta?.name ?? "materia-subject-index-historico",
    path: historicohkCcXeCsKYMeta?.path ?? "historico",
    meta: historicohkCcXeCsKYMeta || {},
    alias: historicohkCcXeCsKYMeta?.alias || [],
    redirect: historicohkCcXeCsKYMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index/historico.vue").then(m => m.default || m)
  },
  {
    name: index9N7gjpOL9aMeta?.name ?? "materia-subject-index",
    path: index9N7gjpOL9aMeta?.path ?? "",
    meta: index9N7gjpOL9aMeta || {},
    alias: index9N7gjpOL9aMeta?.alias || [],
    redirect: index9N7gjpOL9aMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: minha_45contaC5nRlwebHmMeta?.name ?? undefined,
    path: minha_45contaC5nRlwebHmMeta?.path ?? "/minha-conta",
    meta: minha_45contaC5nRlwebHmMeta || {},
    alias: minha_45contaC5nRlwebHmMeta?.alias || [],
    redirect: minha_45contaC5nRlwebHmMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta.vue").then(m => m.default || m),
    children: [
  {
    name: assinaturayLaBqtJsdMMeta?.name ?? "minha-conta-assinatura",
    path: assinaturayLaBqtJsdMMeta?.path ?? "assinatura",
    meta: assinaturayLaBqtJsdMMeta || {},
    alias: assinaturayLaBqtJsdMMeta?.alias || [],
    redirect: assinaturayLaBqtJsdMMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/assinatura.vue").then(m => m.default || m)
  },
  {
    name: index73yEVYZ2JDMeta?.name ?? "minha-conta",
    path: index73yEVYZ2JDMeta?.path ?? "",
    meta: index73yEVYZ2JDMeta || {},
    alias: index73yEVYZ2JDMeta?.alias || [],
    redirect: index73yEVYZ2JDMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/index.vue").then(m => m.default || m)
  },
  {
    name: senhaLp41UtBXYdMeta?.name ?? "minha-conta-senha",
    path: senhaLp41UtBXYdMeta?.path ?? "senha",
    meta: senhaLp41UtBXYdMeta || {},
    alias: senhaLp41UtBXYdMeta?.alias || [],
    redirect: senhaLp41UtBXYdMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/senha.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93Floo5nXwh2Meta?.name ?? "questoes-id",
    path: _91id_93Floo5nXwh2Meta?.path ?? "/questoes/:id()",
    meta: _91id_93Floo5nXwh2Meta || {},
    alias: _91id_93Floo5nXwh2Meta?.alias || [],
    redirect: _91id_93Floo5nXwh2Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/questoes/[id].vue").then(m => m.default || m)
  },
  {
    name: sairvbAL15Uf6IMeta?.name ?? "sair",
    path: sairvbAL15Uf6IMeta?.path ?? "/sair",
    meta: sairvbAL15Uf6IMeta || {},
    alias: sairvbAL15Uf6IMeta?.alias || [],
    redirect: sairvbAL15Uf6IMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/sair.vue").then(m => m.default || m)
  },
  {
    name: sucessoMA1aLvmMgMMeta?.name ?? "sucesso",
    path: sucessoMA1aLvmMgMMeta?.path ?? "/sucesso",
    meta: sucessoMA1aLvmMgMMeta || {},
    alias: sucessoMA1aLvmMgMMeta?.alias || [],
    redirect: sucessoMA1aLvmMgMMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/sucesso.vue").then(m => m.default || m)
  }
]